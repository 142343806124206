import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

import { ImageIcon } from "../../assets/images";
import "react-dropzone-uploader/dist/styles.css";
import "./fileUpload.scss";

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  return (
    <label className="upload-image-wrapper">
      <ImageIcon />
      <span>Add Image/Video</span>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        multiple
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles);
          });
        }}
      />
    </label>
  );
};

const FileUpload = ({ postFileToParent, reportId, uploadSuccess }) => {
  const [currentAllFiles, setCurrentAllFiles] = useState();

  useEffect(() => {
    currentAllFiles && currentAllFiles.forEach((f) => f.remove());
  }, [uploadSuccess, currentAllFiles]);

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    postFileToParent(files[0], reportId);
    setCurrentAllFiles(allFiles);
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <Dropzone
      // getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      maxFiles={1}
      multiple={false}
      accept="image/*,video/*"
      InputComponent={Input}
      getFilesFromEvent={getFilesFromEvent}
      maxSizeBytes={32000000}
    />
  );
};

export default FileUpload;
