import React from "react";
import { InputLabel, Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";

import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import { TrashIcon } from "../../assets/images";

import "./submissionDetails.scss";

const SubmissionDetails = ({
  submissionId,
  details,
  showEditWorkOrderId,
  deleteEditedItem,
  tempWorkOrderID,
  setTempWorkOrderId,
  onClickCancelEdit,
  showEditCaseId,
  tempCaseID,
  setTempCaseId,
  showEditOtdOrderId,
  tempOtdOrderID,
  setTempOtdOrderId,
  getCategoryBasedOnID,
  setShowEditWorkOrderId,
  setShowEditOtdOrderId,
  setShowEditCaseId,
}) => {
  const { t } = useTranslation();

  const onClickEditWorkOrderId = () => {
    setTempWorkOrderId(details.workOrderId !== null ? details.workOrderId : "");
    setShowEditWorkOrderId(true);
  };

  const onClickEditOtdOrderId = () => {
    setTempOtdOrderId(details.otdOrderId !== null ? details.otdOrderId : "");
    setShowEditOtdOrderId(true);
  };

  const onClickEditCaseId = () => {
    setTempCaseId(details.caseId !== null ? details.caseId : "");
    setShowEditCaseId(true);
  };
  return (
    <>
      <Grid container spacing={8} className="grid-conainter">
        <Grid item sm={6} md={3}>
          <div className="details-generic submission-no">
            <InputLabel className="label" htmlFor="submission-id">
              {t("filtersSubmissionNo")}
            </InputLabel>
            <p id="submission-id">{submissionId}</p>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="details-generic submitted">
            <InputLabel className="label" htmlFor="submitted">
              {t("detailsPageLabelSubmitted")}
            </InputLabel>
            <p id="submitted">
              {details.createdAtUtc && `${moment(details.createdAtUtc).utc().format("YYYY-MM-DD HH:mm")} UTC`}
            </p>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="details-generic sharing-bookind-id">
            <InputLabel className="label" htmlFor="sharing-bookind-id">
              {t("detailsPageLabelSharingBookingID")}
            </InputLabel>
            <p id="sharing-bookind-id">{details.bookingId}</p>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="details-generic workorder-id">
            <InputLabel className="label" htmlFor="workorder-id">
              {t("detailsPageLabelWorkorderId")}
            </InputLabel>
            {!showEditWorkOrderId ? (
              <p id="workorder-id" className="edit-text">
                {details.workOrderId || "N/A"}
                {details.workOrderId !== "" && (
                  <span className="delete-icon" onClick={() => deleteEditedItem("workorderid")}>
                    <TrashIcon />
                  </span>
                )}
                <Button
                  className="edit-button"
                  variant="contained"
                  color="primary"
                  onClick={() => onClickEditWorkOrderId(true)}
                  style={details.workOrderId === "" ? { marginLeft: 50 } : {}}
                >
                  <EditIcon />
                  {t("detailsPageEditButton")}
                </Button>
              </p>
            ) : (
              <span className="cancel-edit">
                <input
                  type="text"
                  value={tempWorkOrderID}
                  onChange={(event) => setTempWorkOrderId(event.target.value)}
                  className="edit-input"
                />
                <CancelIcon className="cancel-edit-icon" onClick={() => onClickCancelEdit("workorderid")} />
              </span>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={8} className="grid-conainter">
        <Grid item sm={6} md={3}>
          <div className="details-generic customer-id">
            <InputLabel className="label" htmlFor="customer-id">
              {t("detailsPageLabelCustomerId")}
            </InputLabel>
            <p id="customer-id">{details.userId}</p>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="details-generic market">
            <InputLabel className="label" htmlFor="market">
              {t("detailsPageLabelMarket")}
            </InputLabel>
            <p id="market">{details.market}</p>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="details-generic case-id">
            <InputLabel className="label" htmlFor="case-id">
              {t("detailsPageLabelCaseId")}
            </InputLabel>
            {!showEditCaseId ? (
              <p id="case-id" className="edit-text">
                {details.caseId || "N/A"}
                {details.caseId !== "" && (
                  <span className="delete-icon" onClick={() => deleteEditedItem("caseid")}>
                    <TrashIcon />
                  </span>
                )}
                <Button
                  className="edit-button"
                  variant="contained"
                  color="primary"
                  onClick={() => onClickEditCaseId(true)}
                  style={details.caseId === "" ? { marginLeft: 50 } : {}}
                >
                  <EditIcon />
                  {t("detailsPageEditButton")}
                </Button>
              </p>
            ) : (
              <span className="cancel-edit">
                <input
                  type="text"
                  value={tempCaseID}
                  onChange={(event) => setTempCaseId(event.target.value)}
                  className="edit-input"
                />
                <CancelIcon className="cancel-edit-icon" onClick={() => onClickCancelEdit("caseid")} />
              </span>
            )}
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="details-generic otd-order-id">
            <InputLabel className="label" htmlFor="otd-order-id">
              {t("detailsPageLabelOtdOrderId")}
            </InputLabel>
            {!showEditOtdOrderId ? (
              <p id="otd-order-id" className="edit-text">
                {details.otdOrderId || "N/A"}
                {details.otdOrderId !== "" && (
                  <span className="delete-icon" onClick={() => deleteEditedItem("otdorderid")}>
                    <TrashIcon />
                  </span>
                )}
                <Button
                  className="edit-button"
                  variant="contained"
                  color="primary"
                  onClick={() => onClickEditOtdOrderId(true)}
                  style={details.otdOrderId === "" ? { marginLeft: 50 } : {}}
                >
                  <EditIcon />
                  {t("detailsPageEditButton")}
                </Button>
              </p>
            ) : (
              <span className="cancel-edit">
                <input
                  type="text"
                  value={tempOtdOrderID}
                  onChange={(event) => setTempOtdOrderId(event.target.value)}
                  className="edit-input"
                />
                <CancelIcon className="cancel-edit-icon" onClick={() => onClickCancelEdit("otdorderid")} />
              </span>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={8} className="grid-conainter">
        <Grid item sm={6} md={3}>
          <div className="details-generic vehicle-id">
            <InputLabel className="label" htmlFor="vehicle-id">
              {t("detailsPageLabelVehicleId")}
            </InputLabel>
            <p id="vehicle-id">{details.vehicleId}</p>
          </div>
        </Grid>
        <Grid item sm={6} md={3}>
          <div className="details-generic category">
            <InputLabel className="label" htmlFor="category">
              {t("detailsPageLabelCategory")}
            </InputLabel>
            <p id="category">{getCategoryBasedOnID(details.category)}</p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SubmissionDetails;
