import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

const PrivateRoute = (props) => {
  const { component } = props;
  const Component = component;
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const currentPath = window.location.hostname;

  if (!isAuthenticated) {
    history.push(currentPath);
  }

  return isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/unauthenticated" }} />;
};

export default PrivateRoute;
