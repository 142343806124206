import React from "react";
import { InputLabel, Grid } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { FileUpload } from "../../components";

import { TrashIcon } from "../../assets/images";
import { DownloadIcon } from "../../assets/images";

import "./report.scss";

const ReportItem = ({
  report,
  index,
  error,
  details,
  getCategoryBasedOnID,
  deleteReport,
  onClickDeleteAttachement,
  handleOpenInNewTab,
  download,
  postFileToParent,
  submissionId,
  uploadSuccess,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={8}
      className={`grid-conainter reports-grid ${!(index % 2) && "light-gray"}`}
    >
      {error !== "" && <p className="error">{error}</p>}
      <Grid item sm={1} xs={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="no">
            {t("detailsPageLabelNo")}
          </InputLabel>
          <p id="no">{index + 1}</p>
        </div>
      </Grid>
      <Grid item sm={2} xs={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="report-id">
            {t("detailsPageLabelReportId")}
          </InputLabel>
          <p id="report-id">{report.reportId}</p>
        </div>
      </Grid>
      <Grid item sm={2} xs={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="created">
            {t("detailsPageLabelCreated")}
          </InputLabel>
          {report.occurredAtUtc && details.category !== 1 && (
            <p id="created">{`${moment(report.occurredAtUtc)
              .utc()
              .format("YYYY-MM-DD HH:mm")} UTC`}</p>
          )}
        </div>
      </Grid>
      <Grid item sm={2} xs={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="location">
            {t("detailsPageLabelLocation")}
          </InputLabel>
          <p id="location">{report.location}</p>
        </div>
      </Grid>
      <Grid item sm={2} xs={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="category">
            {t("detailsPageLabelCategory")}
          </InputLabel>
          <p id="category">{getCategoryBasedOnID(details.category)}</p>
        </div>
      </Grid>
      <Grid item sm={1} xs={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="case">
            {t("detailsPageLabelCause")}
          </InputLabel>
          <p id="case">{report.cause}</p>
        </div>
      </Grid>
      <Grid item sm={1} xs={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="recurring">
            {t("detailsPageLabelRecurring")}
          </InputLabel>
          {details.category !== 1 && (
            <p id="recurring">{report.recurrence ? "YES" : "NO"}</p>
          )}
        </div>
      </Grid>
      <Grid item sm={1} xs={6}>
        <div
          className="reports-generic delete-icon"
          onClick={(id) => deleteReport(report.reportId)}
        >
          <TrashIcon />
        </div>
      </Grid>
      <Grid item sm={6}>
        <div className="reports-generic">
          <InputLabel className="label" htmlFor="recurring">
            {t("detailsPageLabelDescription")}
          </InputLabel>
          <p id="recurring">{report.description}</p>
        </div>
      </Grid>
      <Grid container spacing={3} className="second-level-grid-conainter">
        <span className="attachment-label">
          {t("detailsPageLabelAttachments")} (
          {report.attachments && report.attachments.length})
        </span>
        {report.attachments &&
          report.attachments.map((attachment) => (
            <Grid
              item
              sm={2}
              xs={6}
              className="image-grid-wrapper"
              key={attachment.attachmentId}
            >
              <span
                className="delete-attachment"
                onClick={() =>
                  onClickDeleteAttachement(
                    attachment.attachmentId,
                    report.reportId
                  )
                }
              >
                <TrashIcon />
              </span>
              {attachment.contentType &&
              attachment.contentType.includes("video") ? (
                <>
                  <div
                    onClick={(e) =>
                      handleOpenInNewTab(e, attachment.absoluteUri)
                    }
                    className="video-wrapper"
                  >
                    <PlayCircleOutlineIcon fontSize="large" />
                    <span>{t("detailsPageLabelVideo")}</span>
                    <span
                      className="download-icon-wrapper"
                      onClick={(e) => download(e, attachment.attachmentId)}
                    >
                      <DownloadIcon />
                    </span>
                  </div>
                  <p>{attachment.positionName}</p>
                </>
              ) : (
                <div
                  onClick={(e) => handleOpenInNewTab(e, attachment.absoluteUri)}
                  className="image-wrapper"
                >
                  <img src={attachment.absoluteUri} alt="report-incident" />
                  <span
                    className="download-icon-wrapper"
                    onClick={(e) => download(e, attachment.attachmentId)}
                  >
                    <DownloadIcon />
                  </span>
                  <p className="attachment-position-name">
                    {attachment.positionName}
                  </p>
                </div>
              )}
            </Grid>
          ))}
        <Grid item sm={2} xs={6} className="image-grid-wrapper">
          <FileUpload
            postFileToParent={postFileToParent}
            reportId={report.reportId}
            submissionId={submissionId}
            uploadSuccess={uploadSuccess}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportItem;
