import { FETCH_MAIN_PAGE_TABLE_DATA, FETCH_MAIN_PAGE_TABLE_DATA_ERROR } from "../actions/actionNames";

const initialState = {
  tableData: {
    paginationInfo: {
      totalPages: 100,
      rowsPerPage: 50,
      currentPage: 1,
    },
    tableHeaders: [],
    tableRows: [],
  },
  error: "",
};

const fetchTableData = (state, tableData) => ({ ...state, tableData, error: "" });
const fetchTableDataError = (state, error) => ({ ...state, error });

const mainPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAIN_PAGE_TABLE_DATA:
      return fetchTableData(state, action.payload);
    case FETCH_MAIN_PAGE_TABLE_DATA_ERROR:
      return fetchTableDataError(state, action.error);
    default:
      return state;
  }
};

export default mainPageReducer;
