import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import { Logo, MenuIconExclamation, MenuIconGdpr } from "../../assets/images";

import "./header.scss";

const Header = (props) => {
  const [active, setActive] = useState("damage");

  useEffect(() => {
    if (window.location.pathname.indexOf("gdpr") !== -1) {
      setActive("gdpr");
    } else {
      setActive("damage");
    }
  }, []);

  const goToPage = (page) => {
    props.history.push(page === "damage" ? "/" : "/gdpr");
  };

  return (
    <Grid container spacing={8} className="grid-header-container">
      <Grid item sm={12} className="header-grid-wrapper">
        <div className="header">
          <div className="menu-container">
            <ul className="menu">
              <li className={`menu-item ${active === "damage" && "active"}`} onClick={(page) => goToPage("damage")}>
                <span>
                  <MenuIconExclamation /> Damage Log Reports
                </span>
              </li>
              <li className={`menu-item ${active === "gdpr" && "active"}`} onClick={(page) => goToPage("gdpr")}>
                <span>
                  <MenuIconGdpr /> GDPR
                </span>
              </li>
            </ul>
          </div>
          <div className="logo-wrapper">
            <Logo />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(Header);
