import React from "react";
import { useHistory } from "react-router-dom";
import { Logo } from "../../assets/images";
import { useMsal } from "@azure/msal-react";
import { Button } from "@material-ui/core";

import "./unauthenticated.scss";

const Unauthenticated = () => {
  const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();

  async function handleLogin() {
    try {
      await instance.loginPopup();
    } catch (error) {
      const errorMessage = String(error.message);
      const isUserCancelledError = errorMessage.includes("cancelled");

      if (!isUserCancelledError) {
        alert("An error occurred during login. Please try again later.");
      }
    }
  }

  React.useEffect(() => {
    if (accounts.length > 0) {
      history.goBack();
    }
  }, [accounts, history]);

  return (
    <div className="container">
      <div className="header">
        <Logo />
      </div>
      <h1>Login to continue</h1>
      <div className="login-prompt">
        <Button variant="contained" color="primary" onClick={handleLogin} disabled={inProgress === "login"}>
          {inProgress === "login" ? "Loading" : "Login"}
        </Button>
      </div>
    </div>
  );
};

export default Unauthenticated;
