import axios from "./interceptors";

export const GET = (url, headers = {}) => {
  headers["Authorization"] = "";
  return axios.get(`${url}`, {
    headers: {
      ...headers,
    },
  });
};

export const DELETE = (url, headers = {}, data) => {
  headers["Authorization"] = "";
  return axios.delete(`${url}`, {
    headers: {
      ...headers,
    },
    data,
  });
};

export const POST = (url, headers = {}, data) => {
  headers["Authorization"] = "";
  return axios.post(`${url}`, data, {
    headers: {
      ...headers,
    },
  });
};

export const PUT = (url, headers = {}, data) => {
  headers["Authorization"] = "";
  return axios.put(`${url}`, data, {
    headers: {
      ...headers,
    },
  });
};
