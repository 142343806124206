import * as types from "./actionNames";
import { getGdprUserApiCall, deleteUserByCustomerIdApiCall } from "../services";

export const getGdprUser = (customerId) => async (dispatch) => {
  try {
    const response = await getGdprUserApiCall(customerId);
    if ((response && response.status === 200) || response.status === 201) {
      dispatch(getGdprUserSuccess(response.data));
    } else {
      dispatch(getGdprUserError(true));
    }
  } catch (error) {
    dispatch(getGdprUserError(true));
  }
};

export const deleteUserByCustomerId = (customerId) => async (dispatch) => {
  try {
    const response = await deleteUserByCustomerIdApiCall(customerId);
    if ((response && response.status === 200) || response.status === 204) {
      dispatch(deleteUserByCustomerIdSuccess(true));
    } else {
      dispatch(deleteUserByCustomerIdSuccess(true));
    }
  } catch (error) {
    dispatch(deleteUserByCustomerIdError(true));
  }
};

const getGdprUserSuccess = (response) => ({
  type: types.GET_GDFPR_USER_SUCCESS,
  payload: response,
});

const getGdprUserError = (error) => ({
  type: types.GET_GDFPR_USER_ERROR,
  error,
});

const deleteUserByCustomerIdSuccess = (response) => ({
  type: types.DELETE_USER_BY_CUSTOMER_ID_SUCCESS,
  payload: response,
});

const deleteUserByCustomerIdError = (error) => ({
  type: types.DELETE_USER_BY_CUSTOMER_ID_ERROR,
  error,
});
