import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MainPage from "../modules/mainPage";
import Details from "../modules/detailsPage";
import AddSubmission from "../modules/addSubmissionPage";
import GdprPage from "../modules/gdprPage";
import { PrivateRoute, Unauthenticated, NotFoundPage } from "../components";
import { LoadingScreen, Header } from "../components";

const AppRoute = () => (
  <BrowserRouter>
    <Header />
    <Switch>
      <Route path="/unauthenticated" component={Unauthenticated} />

      <PrivateRoute exact path="/" component={MainPage} />
      <PrivateRoute path="/gdpr" component={GdprPage} />
      <PrivateRoute path="/details" component={Details} />
      <PrivateRoute path="/add-submission" component={AddSubmission} />
      <PrivateRoute component={NotFoundPage} />
    </Switch>
    <LoadingScreen />
  </BrowserRouter>
);

export default AppRoute;
