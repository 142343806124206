import { GET } from "../../../utils/http";
import { getBaseApi, paths } from "../../../utils/config";

export const getTableDataFromAPI = (
  itemsPerPage,
  page,
  submissionId,
  customerId,
  vehicleId,
  market,
  category,
  start,
  end,
  latestFirst,
  reportType,
  workOrderId,
  otdOrderId,
  caseId,
  sharingBookingId
) =>
  GET(
    `${getBaseApi()}${
      paths.FETCH_TABLE_DATA
    }?itemsPerPage=${itemsPerPage}&page=${page}&submissionId=${submissionId}&customerId=${customerId}&vehicleId=${vehicleId}&market=${market}&category=${category}&start=${start}&end=${end}&latestFirst=${latestFirst}&reportType=${reportType}&bookingId=${sharingBookingId}&caseId=${caseId}&workOrderId=${workOrderId}&otdOrderId=${otdOrderId}`
  );
