export const FETCH_DETAILS_BASED_ON_SUBMISSION_ID = "DETAILS_PAGE::FETCH_DETAILS_BASED_ON_SUBMISSION_ID";
export const FETCH_DETAILS_BASED_ON_SUBMISSION_ID_ERROR = "DETAILS_PAGE::FETCH_DETAILS_BASED_ON_SUBMISSION_ID_ERROR";

export const DELETE_REPORT_SUCCESS = "DETAILS_PAGE::DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_ERROR = "DETAILS_PAGE::DELETE_REPORT_ERROR";

export const DELETE_ATTACHMENT_SUCCESS = "DETAILS_PAGE::DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_ERROR = "DETAILS_PAGE::DELETE_ATTACHMNT_ERROR";

export const UPLOAD_SUCCESS = "DETAILS_PAGE::UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "DETAILS_PAGE::UPLOAD_ERROR";

export const UPDATE_SUBMISSION_SUCCESS = "DETAILS_PAGE::UPDATE_SUBMISSION_SUCCESS";
export const UPDATE_SUBMISSION_ERROR = "DETAILS_PAGE::UPDATE_SUBMISSION_ERROR";

export const CREATE_REPORT_SUCCESS = "DETAILS_PAGE::CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_ERROR = "DETAILS_PAGE::CREATE_REPORT_ERROR";

export const DOWNLOAD_FILE_SUCCESS = "DETAILS_PAGE::DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_PENDING = "DETAILS_PAGE::DOWNLOAD_FILE_PENDING";
