import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@material-ui/core";

export default function AlertDialog({
  open,
  content,
  title,
  agreeButtonText,
  cancelButtonText,
  agreeColor,
  cancelColor,
  onAgreeClick,
  onCancelClick,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancelClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color={cancelColor}>
            {cancelButtonText}
          </Button>
          <Button onClick={onAgreeClick} color={agreeColor} autoFocus>
            {agreeButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
