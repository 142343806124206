const { REACT_APP_PROTOCOL, REACT_APP_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } = process.env;

export const getBaseApi = () => `${REACT_APP_PROTOCOL}://${REACT_APP_BASE_URL}`;

export const paths = {
  FETCH_TABLE_DATA: "/portal/submission/search",
  FETCH_REPORTS_DETAILS: "/portal/submission",
  DELETE_REPORT: "/portal/submission",
  CREATE_SUBMISSION: "/portal/submission/create",
  CREATE_REPORT: "/portal/submission/",
  GET_GDPR_USER: "/gdpr/customer/",
};

export const SUBSCRIPTION_KEY_VERIFICATION = REACT_APP_SUBSCRIPTION_KEY;
