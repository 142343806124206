import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import mainPageReducer from "../modules/mainPage/reducer";
import detailsPageReducer from "../modules/detailsPage/reducer";
import addSubmissionReducer from "../modules/addSubmissionPage/reducer";
import gdprPageReducer from "../modules/gdprPage/reducer";

const rootReducer = combineReducers({
  globalReducer,
  mainPage: mainPageReducer,
  detailsPage: detailsPageReducer,
  addSubmission: addSubmissionReducer,
  gdprPage: gdprPageReducer,
  reports: [],
});
export default rootReducer;
