import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import fileDownload from "js-file-download";
import axios from "axios";
import { saveAs } from "file-saver";

import {
  getDetailsBasedOnSubmissionId,
  deleteReportApiCall,
  deleteAttachmentForReport,
  postFileUpload,
  updateSubmission,
  createReport,
  downloadFile,
} from "./actions";

import { SUBSCRIPTION_KEY_VERIFICATION } from "../../utils/config";

import { DetailsPDFPage, Modal, Report, SubmissionDetails, AddReport, LoadingScreen } from "../../components";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { ExportIcon } from "../../assets/images";
import { getCategoryBasedOnID, getParameterByName } from "../../utils/utils";

import "./detailsPage.scss";

const DetailsPage = (props) => {
  const { t } = useTranslation();
  const [submissionId, setSubmissionId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteAttachmentObj, setDeleteAttachementObj] = useState({
    attachmentId: "",
    attachmentReportId: "",
  });
  const [tempWorkOrderID, setTempWorkOrderId] = useState("");
  const [tempOtdOrderID, setTempOtdOrderId] = useState("");
  const [tempCaseID, setTempCaseId] = useState("");
  const [openModalDeleteAttachment, setOpenModalDeleteopenModalDeleteAttachment] = useState(false);
  const [reportIdForDeletion, setReportIdForDeletion] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showEditWorkOrderId, setShowEditWorkOrderId] = useState(false);
  const [showEditOtdOrderId, setShowEditOtdOrderId] = useState(false);
  const [showEditCaseId, setShowEditCaseId] = useState(false);

  const [showAddReport, setShowAddReport] = useState(false);
  const [showPresignedUrlPopup, setShowPreSignedUrlPopup] = useState(false);

  const dispatch = useDispatch();
  const {
    details,
    error,
    deleteSuccess,
    deleteAttachment,
    uploadSuccess,
    updateSubmissionStatus,
    createReportStatus,
    fileBlob,
    fileDownloadPending,
  } = useSelector((state) => state.detailsPage);

  useEffect(() => {
    if (fileBlob !== "") {
      fileDownload(fileBlob, `attachment.${fileBlob.type.split("/")[1]}`);
    }
  }, [fileBlob]);

  useEffect(() => {
    const submissionId = getParameterByName("submissionId");
    setSubmissionId(submissionId);
    dispatch(getDetailsBasedOnSubmissionId(submissionId));
    setShowEditCaseId(false);
    setShowEditOtdOrderId(false);
    setShowEditWorkOrderId(false);
    setShowAddReport(false);
  }, [deleteSuccess, deleteAttachment, uploadSuccess, updateSubmissionStatus, createReportStatus]);

  useEffect(() => {
    if (!showEditCaseId && !showEditOtdOrderId && !showEditWorkOrderId) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [showEditCaseId, showEditWorkOrderId, showEditOtdOrderId]);

  const goBackToSearch = () => {
    props.history.push(`/`);
  };

  const deleteReport = (reportId) => {
    setOpenModal(true);
    setReportIdForDeletion(reportId);
  };

  const onAgreeClick = () => {
    dispatch(deleteReportApiCall(submissionId, reportIdForDeletion));
    setOpenModal(false);
  };

  const onCancelClick = () => {
    setOpenModal(false);
  };

  const onClickDeleteAttachement = (attachmentId, reportId) => {
    setDeleteAttachementObj({
      attachmentId,
      attachmentReportId: reportId,
    });
    setOpenModalDeleteopenModalDeleteAttachment(true);
  };

  const onAgreeClickDeleteAttachment = () => {
    dispatch(
      deleteAttachmentForReport(deleteAttachmentObj.attachmentId, deleteAttachmentObj.attachmentReportId, submissionId)
    );
    setOpenModalDeleteopenModalDeleteAttachment(false);
  };
  const onCancelClickDeleteAttachment = () => {
    setOpenModalDeleteopenModalDeleteAttachment(false);
  };

  const download = (e, currentAttachmentId) => {
    e.stopPropagation();
    dispatch(downloadFile(currentAttachmentId));
  };

  const handleOpenInNewTab = (e, attachmentUrl) => {
    e.stopPropagation();
    const win = window.open(attachmentUrl, "_blank");
    win.focus();
  };

  const postFileToParent = (file, reportIdReveived) => {
    dispatch(postFileUpload(file, submissionId, reportIdReveived));
  };

  const deleteEditedItem = (type) => {
    let localCaseId = details.caseId && tempCaseID === "" ? details.caseId : tempCaseID;
    let localOtdOrderId = details.otdOrderId && tempOtdOrderID === "" ? details.otdOrderId : tempOtdOrderID;
    let localWorkOrderId = details.workOrderId && tempWorkOrderID === "" ? details.workOrderId : tempWorkOrderID;

    switch (type) {
      case "caseid":
        dispatch(updateSubmission(submissionId, "", localWorkOrderId, localOtdOrderId));
        break;
      case "workorderid":
        dispatch(updateSubmission(submissionId, localCaseId, "", localOtdOrderId));
        break;
      case "otdorderid":
        dispatch(updateSubmission(submissionId, localCaseId, localWorkOrderId, ""));
        break;

      default:
        break;
    }
  };

  const onClickCancelEdit = (type) => {
    switch (type) {
      case "workorderid":
        setShowEditWorkOrderId(false);
        break;
      case "caseid":
        setShowEditCaseId(false);
        break;
      case "otdorderid":
        setShowEditOtdOrderId(false);
        break;

      default:
        break;
    }
  };

  const onSaveClick = () => {
    let localCaseId = details.caseId && tempCaseID === "" ? details.caseId : tempCaseID;
    let localOtdOrderId = details.otdOrderId && tempOtdOrderID === "" ? details.otdOrderId : tempOtdOrderID;
    let localWorkOrderId = details.workOrderId && tempWorkOrderID === "" ? details.workOrderId : tempWorkOrderID;

    dispatch(updateSubmission(submissionId, localCaseId, localWorkOrderId, localOtdOrderId));
  };

  const addNewReport = (location, cause, reucurrence, occuredAt, description, file) => {
    dispatch(createReport(submissionId, location, cause, reucurrence, occuredAt, description, file));
  };

  const onExportClick = async (e) => {
    //check if there are any attachments and do get to see if pre-signed url still active otherwise, if you get errro
    //show a pop-up to refresh the page
    if (
      details &&
      details.reports &&
      details.reports[0].attachments &&
      details.reports[0].attachments.length !== 0 &&
      details.reports[0].attachments[0].absoluteUri
    ) {
      axios
        .get(details.reports[0].attachments[0].absoluteUri)
        .then(async (response) => {
          const doc = <DetailsPDFPage pdfDetails={details} />;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, "damageLog.pdf");
        })
        .catch((error) => {
          setShowPreSignedUrlPopup(true);
        });
    } else {
      const doc = <DetailsPDFPage pdfDetails={details} />;
      const asPdf = pdf([]); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, "damageLog.pdf");
    }
  };

  const onReloadPageForNewPreSignedUrlClick = () => {
    window.location.reload();
  };

  return (
    <div className="details-page">
      {fileDownloadPending && (
        <div className="loading-download-container">
          <div className="loader">
            <CircularProgress size={100} color="primary" />
          </div>
        </div>
      )}
      <Modal
        open={showPresignedUrlPopup}
        content={`${t("detailsPageShowPresignedUrlModalContent")}`}
        title={t("detailsPageShowPresignedUrlModalTitle")}
        agreeButtonText={t("detailsPageShowPresignedUrlModalConfirm")}
        cancelButtonText={t("detailsPageCancelModal")}
        agreeColor="primary"
        cancelColor="secondary"
        onAgreeClick={onReloadPageForNewPreSignedUrlClick}
        onCancelClick={() => setShowPreSignedUrlPopup(false)}
      />
      <Modal
        open={openModal}
        content={`${t("detailsPageDeleteReportIdTextModal")} ${reportIdForDeletion}?`}
        title={t("detailsPageDeleteReportTitleModal")}
        agreeButtonText={t("detailsPageConfirmModal")}
        cancelButtonText={t("detailsPageCancelModal")}
        agreeColor="primary"
        cancelColor="secondary"
        onAgreeClick={onAgreeClick}
        onCancelClick={onCancelClick}
      />
      <Modal
        open={openModalDeleteAttachment}
        content={`${t("detailsPageDeleteAttachmentIdTextModal")} ${deleteAttachmentObj.attachmentId}?`}
        title={t("detailsPageDeleteAttachmentTitleModal")}
        agreeButtonText={t("detailsPageConfirmModal")}
        cancelButtonText={t("detailsPageCancelModal")}
        agreeColor="primary"
        cancelColor="secondary"
        onAgreeClick={onAgreeClickDeleteAttachment}
        onCancelClick={onCancelClickDeleteAttachment}
      />
      <p className="back-to-search" onClick={goBackToSearch}>
        {t("detailsPageBack")}
      </p>
      <div className="text-center error-container">
        <span className="error-msg">{details.userId === -1 && t("gdprDataForThisCustomerIdHasBeenDeleted")}</span>
      </div>
      <div className="title-container">
        <h2 className="details-title">{t("detailsPageTitle")}</h2>
        <div className="action-buttons-container">
          <Button className="export-button" variant="contained" color="primary" onClick={onExportClick}>
            <ExportIcon /> {t("detailsPageExportButton")}
          </Button>

          {showSaveButton && (
            <Button className="save-button" variant="contained" onClick={() => onSaveClick()}>
              {t("detailsPageSaveButton")}
            </Button>
          )}
        </div>
      </div>

      <SubmissionDetails
        submissionId={submissionId}
        details={details}
        showEditWorkOrderId={showEditWorkOrderId}
        deleteEditedItem={deleteEditedItem}
        tempWorkOrderID={tempWorkOrderID}
        setTempWorkOrderId={setTempWorkOrderId}
        onClickCancelEdit={onClickCancelEdit}
        showEditCaseId={showEditCaseId}
        tempCaseID={tempCaseID}
        setTempCaseId={setTempCaseId}
        showEditOtdOrderId={showEditOtdOrderId}
        tempOtdOrderID={tempOtdOrderID}
        setTempOtdOrderId={setTempOtdOrderId}
        getCategoryBasedOnID={getCategoryBasedOnID}
        setShowEditWorkOrderId={setShowEditWorkOrderId}
        setShowEditOtdOrderId={setShowEditOtdOrderId}
        setShowEditCaseId={setShowEditCaseId}
      />

      <h3 className="details-title second-title">
        {t("detailsPageLabelReports")} ({details.reports ? details.reports.length : 0})
      </h3>

      <Button className="add-report-button" variant="contained" onClick={() => setShowAddReport(!showAddReport)}>
        {t("detailsPageAddReport")}
      </Button>

      {showAddReport && (
        <AddReport
          category={details.category}
          details={details}
          setShowAddReport={setShowAddReport}
          submissionId={submissionId}
          uploadSuccess={uploadSuccess}
          addNewReport={addNewReport}
        />
      )}

      {details.reports &&
        details.reports.length !== 0 &&
        details.reports.map((report, index) => (
          <React.Fragment key={report.reportId}>
            <Report
              report={report}
              index={index}
              error={error}
              details={details}
              getCategoryBasedOnID={getCategoryBasedOnID}
              deleteReport={deleteReport}
              onClickDeleteAttachement={onClickDeleteAttachement}
              handleOpenInNewTab={handleOpenInNewTab}
              download={download}
              postFileToParent={postFileToParent}
              submissionId={submissionId}
              uploadSuccess={uploadSuccess}
            />
          </React.Fragment>
        ))}
    </div>
  );
};

export default withRouter(DetailsPage);
