import React from "react";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

import { ImageIcon } from "../../assets/images";
import "react-dropzone-uploader/dist/styles.css";
import "./fileUploadNoSubmit.scss";

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  return (
    <label className="upload-image-wrapper">
      <ImageIcon />
      <span>Add Image/Video</span>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        multiple
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles);
          });
        }}
      />
    </label>
  );
};

const FileUploadNoSubmit = ({ uploadSuccess, handleChangeStatusFile }) => {
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <Dropzone
      // getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatusFile}
      maxFiles={1}
      multiple={false}
      accept="image/*,video/*"
      InputComponent={Input}
      getFilesFromEvent={getFilesFromEvent}
      maxSizeBytes={32000000}
    />
  );
};

export default FileUploadNoSubmit;
