import * as types from "./actionNames";
import { getTableDataFromAPI } from "../services";

export const getTableData = (
  itemsPerPage,
  page,
  submissionId,
  customerId,
  vehicleId,
  market,
  category,
  start,
  end,
  latestFirst,
  reportType,
  workOrderId,
  otdOrderId,
  caseId,
  sharingBookingId
) => async (dispatch) => {
  try {
    const response = await getTableDataFromAPI(
      itemsPerPage,
      page,
      submissionId,
      customerId,
      vehicleId,
      market,
      category,
      start,
      end,
      latestFirst,
      reportType,
      workOrderId,
      otdOrderId,
      caseId,
      sharingBookingId
    );
    // TO DO: ADD workorderId, otdId and case id in query string params

    if ((response && response.status === 200) || response.status === 201) {
      dispatch(dispatchFetchTableDataSuccess(response.data));
    } else {
      dispatch(dispatchFetchTableDataError(response.response.data.message || response.response.data[0].message));
    }
  } catch (error) {
    dispatch(dispatchFetchTableDataError("We are sorry but there was an error with your request!"));
  }
};

const dispatchFetchTableDataSuccess = (counter) => ({
  type: types.FETCH_MAIN_PAGE_TABLE_DATA,
  payload: counter,
});

const dispatchFetchTableDataError = (error) => ({
  type: types.FETCH_MAIN_PAGE_TABLE_DATA_ERROR,
  error,
});
