import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputLabel,
  Grid,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { getGdprUser, deleteUserByCustomerId } from "./actions";
import { getBaseApi } from "../../utils/config";
import { Modal } from "../../components";
import { SUBSCRIPTION_KEY_VERIFICATION } from "../../utils/config";

import "./gdprPage.scss";

import { TrashIcon2 } from "../../assets/images";

const GdprPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentCustomerIdFromTableData, setCurrentCustomerIdFromTableData] = useState("");
  const { tableData, error, deleteSuccess } = useSelector((state) => state.gdprPage);

  useEffect(() => {
    dispatch(getGdprUser(-999));
  }, [window.location.pathname]);

  useEffect(() => {
    if (deleteSuccess !== "not-set") {
      dispatch(getGdprUser(currentCustomerIdFromTableData));
    }
    setCurrentCustomerIdFromTableData(customerId);
  }, [tableData, error, deleteSuccess]);

  const changeCustomerId = (event) => {
    setCustomerId(event.target.value);
  };

  const onSearchClick = () => {
    dispatch(getGdprUser(customerId));
  };

  const countReports = () => {
    let reportsCount = 0;

    tableData.forEach((item) => {
      reportsCount = item.Reports !== null ? reportsCount + item.Reports.length : reportsCount;
    });

    return reportsCount;
  };

  const onExportAsJsonClick = () => {
    const url = `${getBaseApi()}/gdpr/customer/${customerId}/download`;
    // const win = window.open(url, "_blank");
    // win.focus();
    axios
      .get(url, {
        headers: {
          subscriptionKey: SUBSCRIPTION_KEY_VERIFICATION,
        },
      })
      .then(async (response) => {
        const fileName = currentCustomerIdFromTableData;
        const json = JSON.stringify(response.data);
        const blob = new Blob([json], { type: "application/json" });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const onClickDeleteForever = () => {
    setShowDeleteModal(true);
  };

  const onAgreeClick = () => {
    setShowDeleteModal(false);
    dispatch(deleteUserByCustomerId(currentCustomerIdFromTableData));
  };

  const onCancelClick = () => {
    setShowDeleteModal(false);
  };

  return (
    <Paper className="gdpr-page">
      <Modal
        open={showDeleteModal}
        content={`${t("gdprModalDeleteCustomerIdContent")} ${currentCustomerIdFromTableData}?`}
        title={t("gdprModalDeleteCustomerIdTitle")}
        agreeButtonText={t("gdprModalDeleteCustomerIdConfirm")}
        cancelButtonText={t("gdprModalDeleteCustomerIdCancel")}
        agreeColor="primary"
        cancelColor="secondary"
        onAgreeClick={onAgreeClick}
        onCancelClick={onCancelClick}
      />
      <h2 className="gdpr-title">{t("gdprTitle")}</h2>
      <div className="tab-container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <div className="generic-input-wrapper submission-no">
              <InputLabel className="label" htmlFor="submission-no">
                {t("detailsPageLabelCustomerId")}
              </InputLabel>
              <input
                id="submission-no"
                className="input-text"
                type="text"
                value={customerId}
                onChange={changeCustomerId}
              />
            </div>
          </Grid>
          <Button className="submit-button" variant="contained" color="primary" onClick={onSearchClick}>
            <SearchIcon />
            {t("mainPageFiltersSearch")}
          </Button>
        </Grid>

        <ul>
          <li className="selected">{t("gdprResult")}</li>
        </ul>
      </div>
      {error !== "" && (
        <Grid container spacing={3} className="error-wrapper">
          <Typography align="center" color="error">
            {error}
          </Typography>
        </Grid>
      )}
      {error === "" && (
        <>
          <TableContainer className="table-container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell className="table-head">{t("gdprNo")}</TableCell>
                  <TableCell className="table-head">{t("detailsPageLabelCustomerId")}</TableCell>
                  <TableCell className="table-head">{t("gdprSubmissions")}</TableCell>
                  <TableCell className="table-head">{t("gdprReports")}</TableCell>
                  <TableCell className="table-head">{""}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData && tableData.length !== 0 ? (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell>1</TableCell>
                    <TableCell>{currentCustomerIdFromTableData}</TableCell>
                    <TableCell>{tableData.length}</TableCell>
                    <TableCell>{countReports()}</TableCell>
                    <TableCell className="actions-column">
                      <Button className="download-as-json-button" variant="contained" onClick={onExportAsJsonClick}>
                        <SearchIcon />
                        {t("gdprExportAsJson")}
                      </Button>
                      <Button
                        className="delete-button"
                        variant="contained"
                        color="primary"
                        onClick={onClickDeleteForever}
                      >
                        <TrashIcon2 />
                        {t("gdprDeleteDataForever")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
};

export default withRouter(GdprPage);
