import { CREATE_SUBMISSION_SUCCESS, CREATE_SUBMISSION_ERROR } from "../actions/actionNames";

const initialState = {
  createSubmissionSuccess: {
    submissionId: "",
  },
  error: false,
};

const createSubmissionSuccess = (state, createSubmissionResponse) => ({
  createSubmissionSuccess: {
    submissionId: createSubmissionResponse,
  },
  error: false,
});
const createSubmissionError = (state, error) => ({
  createSubmissionSuccess: {
    submissionId: "",
  },
  error,
});

const addSubmissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBMISSION_SUCCESS:
      return createSubmissionSuccess(state, action.payload);
    case CREATE_SUBMISSION_ERROR:
      return createSubmissionError(state, true);

    default:
      return state;
  }
};

export default addSubmissionReducer;
