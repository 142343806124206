import React, { useState, useEffect } from "react";
import { InputLabel, Select, Grid, TextField, Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./filters.scss";

const Filters = ({ submitFiltersParent, customerIdFromUrl }) => {
  const { t } = useTranslation();
  const [market, setMarket] = useState("");
  const [category, setCategory] = useState("");
  const [submissionNo, setSubmissionNo] = useState("");
  const [sharingBookingId, setSharingBookingId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [workOrderId, setWorkorderId] = useState("");
  const [otdOrderId, setOtdOrderId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [timeAndDate, setTimeAndDate] = useState("");
  const [timeAndDateUnformatted, setTimeAndDateUnformatted] = useState("");
  const [timeAndDateEndUnformatted, setTimeAndDateEndUnformatted] = useState("");
  const [timeAndDateEnd, setTimeAndDateEnd] = useState("");

  useEffect(() => {
    if (customerIdFromUrl !== customerId) {
      setCustomerId(customerIdFromUrl);
    }
  }, [customerIdFromUrl]);

  const handleChangeMarket = (event) => {
    setMarket(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const onDateTimeChange = (event) => {
    setTimeAndDateUnformatted(event.target.value);
    setTimeAndDate(moment.parseZone(event.target.value).utc().format());
  };

  const onDateTimeChangeEnd = (event) => {
    setTimeAndDateEndUnformatted(event.target.value);
    setTimeAndDateEnd(moment.parseZone(event.target.value).utc().format());
  };

  const changeSubmissionNo = (event) => {
    setSubmissionNo(event.target.value);
  };

  const changeSharingBookingId = (event) => {
    setSharingBookingId(event.target.value);
  };

  const changeCustomerId = (event) => {
    setCustomerId(event.target.value);
  };

  const changeVehicleId = (event) => {
    setVehicleId(event.target.value);
  };

  const changeWorkorderId = (event) => {
    setWorkorderId(event.target.value);
  };

  const changeOtdOrderId = (event) => {
    setOtdOrderId(event.target.value);
  };

  const changeCaseId = (event) => {
    setCaseId(event.target.value);
  };

  const submitFilters = () => {
    submitFiltersParent(
      market,
      category,
      submissionNo,
      sharingBookingId,
      customerId,
      vehicleId,
      timeAndDate,
      timeAndDateEnd,
      workOrderId,
      otdOrderId,
      caseId
    );
  };

  const resetFilters = (e) => {
    e.stopPropagation();
    setTimeAndDateUnformatted("");
    setTimeAndDateEndUnformatted("");
    setTimeAndDate("");
    setTimeAndDateEnd("");

    setMarket("");
    setCategory("");
    setSubmissionNo("");
    setSharingBookingId("");
    setCustomerId("");
    setVehicleId("");
    setOtdOrderId("");
    setCaseId("");
    setWorkorderId("");

    submitFiltersParent("", "", "", "", "", "", "", "", "", "", "");
  };

  return (
    <div className="filters-container">
      <h2 className="filters-title">{t("mainPageFiltersTitle")}</h2>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter submission-no">
            <InputLabel className="label" htmlFor="submission-no">
              {t("filtersSubmissionNo")}
            </InputLabel>
            <input
              id="submission-no"
              className="input-text"
              type="text"
              value={submissionNo}
              onChange={changeSubmissionNo}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter market-select">
            <InputLabel className="label" htmlFor="market-select">
              {t("detailsPageLabelMarket")}
            </InputLabel>
            <Select id="market-select" native value={market} onChange={handleChangeMarket}>
              <option value="">{t("generalLabelAll")}</option>
              <option value="SE">SE</option>
              <option value="NL">NL</option>
              <option value="BE">BE</option>
              <option value="DE">DE</option>
              <option value="IT">IT</option>
              <option value="ES">ES</option>
              <option value="FR">FR</option>
            </Select>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter sharing-booking-id">
            <InputLabel className="label" htmlFor="sharing-booking-id">
              {t("filtersSharing")} <br />
              {t("filtersBookingId")}
            </InputLabel>
            <input
              id="sharing-booking-id"
              className="input-text"
              type="text"
              value={sharingBookingId}
              onChange={changeSharingBookingId}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter time-and-date">
            <InputLabel className="label" htmlFor="datetime-local">
              {t("filtersTimeAndDateStart")}
            </InputLabel>
            <TextField
              id="datetime-local"
              type="datetime-local"
              value={timeAndDateUnformatted}
              onChange={onDateTimeChange}
            />
          </div>
        </Grid>
      </Grid>

      {/* second row of filters*/}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter customer-id">
            <InputLabel className="label" htmlFor="customer-id">
              {t("detailsPageLabelCustomerId")}
            </InputLabel>
            <input id="customer-id" className="input-text" type="text" value={customerId} onChange={changeCustomerId} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter category-select">
            <InputLabel className="label" htmlFor="category-select">
              {t("detailsPageLabelCategory")}
            </InputLabel>
            <Select id="market-select" native value={category} onChange={handleChangeCategory}>
              <option value="">{t("generalLabelAll")}</option>
              <option value="1">Car Sharing</option>
              <option value="2">Damage</option>
              <option value="3">Problem</option>
              <option value="4">Other</option>
              <option value="5">Return</option>
              <option value="6">Delivery</option>
            </Select>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter workorder-id">
            <InputLabel className="label" htmlFor="workorder-id">
              {t("detailsPageLabelWorkorderId")}
            </InputLabel>
            <input
              id="workorder-id"
              className="input-text"
              type="text"
              value={workOrderId}
              onChange={changeWorkorderId}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter time-and-date">
            <InputLabel className="label" htmlFor="datetime-local">
              {t("filtersTimeAndDateEnd")}
            </InputLabel>
            <TextField
              id="datetime-local"
              type="datetime-local"
              value={timeAndDateEndUnformatted}
              onChange={onDateTimeChangeEnd}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter vehicle-id">
            <InputLabel className="label" htmlFor="vehicle-id">
              {t("detailsPageLabelVehicleId")}
            </InputLabel>
            <input id="vehicle-id" className="input-text" type="text" value={vehicleId} onChange={changeVehicleId} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter otd-order-id">
            <InputLabel className="label" htmlFor="otd-order-id">
              {t("detailsPageLabelOtdOrderId")}
            </InputLabel>
            <input
              id="otd-order-id"
              className="input-text"
              type="text"
              value={otdOrderId}
              onChange={changeOtdOrderId}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="generic-filter case-id">
            <InputLabel className="label" htmlFor="case-id">
              {t("detailsPageLabelCaseId")}
            </InputLabel>
            <input id="case-id" className="input-text" type="text" value={caseId} onChange={changeCaseId} />
          </div>
        </Grid>
      </Grid>

      <div className="submit-area">
        <span className="reset-button" onClick={(e) => resetFilters(e)}>
          <RefreshIcon className="reset-icon" />
          {t("mainPageFiltersResetFilters")}
        </span>
        <Button className="submit-button" variant="contained" color="primary" onClick={submitFilters}>
          <SearchIcon />
          {t("mainPageFiltersSearch")}
        </Button>
      </div>
    </div>
  );
};

export default Filters;
