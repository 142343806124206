import * as types from "./actionNames";
import {
  getDetailsBasedOnSubmissionIdFromApi,
  initiateDeleteReportApiCall,
  postFileToAPI,
  deleteAttachmentCall,
  updateSubmissionApiCall,
  createReportApiCall,
} from "../services";

import { getBaseApi, SUBSCRIPTION_KEY_VERIFICATION } from "../../../utils/config";

export const postFileUpload = (file, submissionId, reportId) => async (dispatch) => {
  const formData = new FormData();
  formData.append("File", file.file);
  formData.append("Position", 7);

  try {
    const response = await postFileToAPI(formData, submissionId, reportId);
    if ((response && response.status === 200) || response.status === 201) {
      dispatch(postFileSuccess(true));
    } else {
      dispatch(postFileError(response.response.data.message));
    }
  } catch (error) {
    dispatch(postFileError(true));
  }
};

export const getDetailsBasedOnSubmissionId = (submissionId) => async (dispatch) => {
  try {
    const response = await getDetailsBasedOnSubmissionIdFromApi(submissionId);
    if (response && response.status === 200) {
      dispatch(getDetailsBasedOnSubmissionIdSuccess(response.data));
    } else {
      dispatch(getDetailsBasedOnSubmissionIdError(true));
    }
  } catch (error) {
    dispatch(getDetailsBasedOnSubmissionIdError(true));
  }
};

export const deleteReportApiCall = (submissionId, reportId) => async (dispatch) => {
  const response = await initiateDeleteReportApiCall(submissionId, reportId);
  if ((response && response.status === 200) || (response && response.status === 204)) {
    dispatch(deleteReportSuccess(true));
  } else {
    dispatch(deleteReportError(response.response.data.message));
  }
};

export const deleteAttachmentForReport = (attachmentId, reportId, submissionId) => async (dispatch) => {
  const response = await deleteAttachmentCall(attachmentId, reportId, submissionId);
  dispatch(deleteAttachmentSuccess("not-set"));
  if ((response && response.status === 200) || (response && response.status === 204)) {
    dispatch(deleteAttachmentSuccess(true));
  } else {
    dispatch(deleteAttachmentError(response.response.data.message));
  }
};

export const updateSubmission = (submissionId, caseId, workorderId, otdOrderId) => async (dispatch) => {
  try {
    const response = await updateSubmissionApiCall(submissionId, caseId, workorderId, otdOrderId);
    if (response && response.status === 200) {
      dispatch(updateSubmissionSuccess(true));
    } else {
      dispatch(updateSubmissionSuccess(true));
    }
  } catch (error) {
    dispatch(updateSubmissionError(true));
  }
};

export const createReport = (
  submissionId,
  location,
  category,
  cause,
  reucurrence,
  occuredAt,
  description,
  file
) => async (dispatch) => {
  try {
    const response = await createReportApiCall(
      submissionId,
      location,
      category,
      cause,
      reucurrence,
      occuredAt,
      description,
      file
    );
    if (response && response.status === 200) {
      dispatch(createReportSuccess(true));
    } else {
      dispatch(createReportSuccess(true));
    }
  } catch (error) {
    dispatch(createReportError(true));
  }
};

export const downloadFile = (currentAttachmentId) => (dispatch) => {
  const url = `${getBaseApi()}/portal/submission/attachment/view/${currentAttachmentId}`;
  dispatch(downloadFilePending(true));

  fetch(url, {
    method: "GET",
    headers: { subscriptionKey: SUBSCRIPTION_KEY_VERIFICATION },
  })
    .then((response) => response.blob())
    .then((response) => {
      dispatch(downloadFileSuccess(response));
      dispatch(downloadFilePending(false));
    })
    .catch((error) => {
      dispatch(downloadFilePending(false));
    });
};

const downloadFilePending = (response) => ({
  type: types.DOWNLOAD_FILE_PENDING,
  status: response,
});

const downloadFileSuccess = (response) => ({
  type: types.DOWNLOAD_FILE_SUCCESS,
  payload: response,
});

const createReportSuccess = (response) => ({
  type: types.CREATE_REPORT_SUCCESS,
  payload: response,
});

const createReportError = (error) => ({
  type: types.CREATE_REPORT_ERROR,
  error,
});

const getDetailsBasedOnSubmissionIdSuccess = (response) => ({
  type: types.FETCH_DETAILS_BASED_ON_SUBMISSION_ID,
  payload: response,
});

const getDetailsBasedOnSubmissionIdError = (error) => ({
  type: types.FETCH_DETAILS_BASED_ON_SUBMISSION_ID_ERROR,
  error,
});

const deleteReportSuccess = (response) => ({
  type: types.DELETE_REPORT_SUCCESS,
  payload: response,
});

const deleteReportError = (error) => ({
  type: types.DELETE_REPORT_ERROR,
  error,
});

const postFileSuccess = (response) => ({
  type: types.UPLOAD_SUCCESS,
  payload: response,
});

const postFileError = (error) => ({
  type: types.UPLOAD_ERROR,
  error,
});

const deleteAttachmentSuccess = (response) => ({
  type: types.DELETE_ATTACHMENT_SUCCESS,
  payload: response,
});

const deleteAttachmentError = (error) => ({
  type: types.DELETE_ATTACHMENT_ERROR,
  error,
});

const updateSubmissionSuccess = (response) => ({
  type: types.UPDATE_SUBMISSION_SUCCESS,
  payload: response,
});

const updateSubmissionError = (error) => ({
  type: types.UPDATE_SUBMISSION_ERROR,
  error,
});
