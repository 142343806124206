export const translationEn = {
  en: {
    translation: {
      genericOccuredAt: "Noticed At",
      genericLocation: "Location",
      genericDescription: "Description",
      genericCause: "Cause",
      genericAttachment: "Attachment",
      genericVehicleId: "Vehicle ID",
      genericCustomerId: "Customer ID",
      genericCustomerIdError: "Customer ID cannot contain decimals",
      genericErrorMandatoryText: "is a mandatory field!",
      genericMarketError: "Market value cannot be 'All'!",
      mainPageAll: "All",
      mainPageIssueReportTab: "Issue reports",
      mainPageConditionReportTab: "Condition reports",
      mainPageSharingReportTab: "Sharing reports",
      mainPageSortLatest: "Latest first",
      mainPageSortOldest: "Oldest first",
      mainPageItemsPerPage: "Items per page:",
      mainPageGo: "Go",
      mainPageFiltersSearch: "SEARCH",
      mainPageFiltersResetFilters: "Reset Filters",
      mainPageFiltersTitle: "Search Issue & Condition Reports",
      mainPageAddSubmission: "+ Add submission",
      detailsPageTitle: "Submission details",
      detailsPageExportButton: "EXPORT",
      detailsPageExportLoadingButton: "Loading Document...",
      detailsPageEditButton: "Edit",
      detailsPageSaveButton: "SAVE",
      detailsPageBack: "< Back to search",
      detailsPageLabelSubmitted: "Submitted",
      detailsPageLabelSharingBookingID: "Sharing booking ID",
      detailsPageLabelWorkorderId: "Workorder ID",
      detailsPageLabelCustomerId: "Customer ID",
      detailsPageLabelMarket: "Market",
      detailsPageLabelCaseId: "Case ID",
      detailsPageLabelOtdOrderId: "OTD Order ID",
      detailsPageLabelVehicleId: "Vehicle ID",
      detailsPageLabelCategory: "Category",
      detailsPageLabelReports: "Reports",
      detailsPageLabelNo: "No",
      detailsPageLabelReportId: "Report ID",
      detailsPageLabelCreated: "Created",
      detailsPageLabelLocation: "Location",
      detailsPageLabelCause: "Cause",
      detailsPageLabelRecurring: "Recurring",
      detailsPageLabelDescription: "Description",
      detailsPageLabelAttachments: "Attachments",
      detailsPageLabelVideo: "Video",
      detailsPageAddReport: "+ Add report",
      detailsPageDeleteReportIdTextModal: "Are you sure you want to delete report with id:",
      detailsPageDeleteReportTitleModal: "Delete report",
      detailsPageConfirmModal: "Confirm",
      detailsPageCancelModal: "Cancel",
      detailsPageDeleteAttachmentTitleModal: "Delete attachment",
      detailsPageDeleteAttachmentIdTextModal: "Are you sure you want to delete attachment with id:",
      detailsPageShowPresignedUrlModalTitle: "Attachment urls expired",
      detailsPageShowPresignedUrlModalConfirm: "Reload",
      detailsPageShowPresignedUrlModalContent:
        "You've been away for too long. Please refresh the page in order to be able to download the PDF.",
      filtersSubmissionNo: "Submission No.",
      filtersTimeAndDateStart: "Time and Date (Start)",
      filtersTimeAndDateEnd: "Time and Date (End)",
      filtersSharing: "Sharing",
      filtersBookingId: "booking ID",
      generalLabelAll: "All",
      pdfSubmissionDetails: "Submission details",
      pdfReports: "Reports",
      pdfNo: "No",
      addSubmissionTitle: "Create Submission",
      addSubmissionSelectCategory: "Select Category",
      addSubmissionCreate: "Create",
      addSubmissionSharingBooking: "Sharing Booking",
      addSubmissionFooterText: "Create the submissions to continue",
      addSubmissionId: "ID",
      addSubmissionCarSharingReport: "Car sharing report",
      addSubmissionDamageReport: "Damage report",
      addSubmissionProblemReport: "Problem report",
      addSubmissionOtherReport: "Other Report",
      addSubmissionReturnReport: "Return report",
      addSubmissionDeliveryReport: "Delivery report",
      addReportOccuredAt: "Noticed at",
      addReportSave: "Save",
      gdprSubmissions: "Submissions",
      gdprReports: "Reports",
      gdprNo: "No.",
      gdprResult: "Result",
      gdprTitle: "GDPR",
      gdprExportAsJson: "Export as JSON",
      gdprDeleteDataForever: "Delete data forever",
      gdprModalDeleteCustomerIdContent: "Are you sure you want to delete customer ID:",
      gdprModalDeleteCustomerIdConfirm: "Confirm",
      gdprModalDeleteCustomerIdCancel: "Cancel",
      gdprModalDeleteCustomerIdTitle: "Delete Customer ID",
      gdprDataForThisCustomerIdHasBeenDeleted: "GDPR data for this customer ID has been deleted",
    },
  },
};
