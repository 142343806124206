import { GET, DELETE, POST } from "../../../utils/http";
import { getBaseApi, paths } from "../../../utils/config";

export const getDetailsBasedOnSubmissionIdFromApi = (submissionId) =>
  GET(`${getBaseApi()}${paths.FETCH_REPORTS_DETAILS}/${submissionId}`);

export const initiateDeleteReportApiCall = (submissionId, reportId) => {
  const url = `${getBaseApi()}${paths.DELETE_REPORT}/${submissionId}/report/${reportId}`;
  return DELETE(url);
};

export const deleteAttachmentCall = (attachmentId, reportId, submissionId) => {
  const url = `${getBaseApi()}${paths.DELETE_REPORT}/${submissionId}/report/${reportId}/attachment/${attachmentId}`;
  return DELETE(url);
};

export const postFileToAPI = (file, submissionId, reportId) => {
  const url = `${getBaseApi()}${paths.FETCH_REPORTS_DETAILS}/${submissionId}/reports/${reportId}/attachment/upsert`;
  return POST(url, {}, file);
};

export const updateSubmissionApiCall = (submissionId, caseId, workorderId, otdOrderId) => {
  const data = {
    caseId,
    workorderId,
    otdOrderId,
  };
  const url = `${getBaseApi()}/portal/submission/${submissionId}/upsert`;
  return POST(url, {}, data);
};

export const createReportApiCall = (submissionId, location, cause, reucurrence, occuredAt, description, file) => {
  const formData = new FormData();
  formData.append("description", description);
  formData.append("cause", cause);
  formData.append("location", location);
  formData.append("reucurrence", reucurrence);
  formData.append("occurredAt", occuredAt);
  formData.append("file", file);
  formData.append("position", 7);
  const url = `${getBaseApi()}${paths.CREATE_REPORT}${submissionId}/report/create`;
  return POST(url, {}, formData);
};
