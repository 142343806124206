import {
  GET_GDFPR_USER_SUCCESS,
  GET_GDFPR_USER_ERROR,
  DELETE_USER_BY_CUSTOMER_ID_SUCCESS,
  DELETE_USER_BY_CUSTOMER_ID_ERROR,
} from "../actions/actionNames";

const initialState = {
  tableData: [],
  error: "",
  deleteSuccess: "not-set",
};

const fetchTableData = (state, tableData) => ({ ...state, tableData, error: "", deleteSuccess: "not-set" });
const fetchTableDataError = (state, error) => ({ ...state, error });

const deleteUserByCustomerId = (state, deleteSuccess) => ({ ...state, deleteSuccess, error: "" });

const gdprPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GDFPR_USER_SUCCESS:
      return fetchTableData(state, action.payload);
    case GET_GDFPR_USER_ERROR:
      return fetchTableDataError(state, action.error);
    case DELETE_USER_BY_CUSTOMER_ID_SUCCESS:
      return deleteUserByCustomerId(state, action.payload);
    case DELETE_USER_BY_CUSTOMER_ID_ERROR:
      return fetchTableDataError(state, action.error);
    default:
      return state;
  }
};

export default gdprPageReducer;
