import axios from "axios";
import store from "../store";

import { SUBSCRIPTION_KEY_VERIFICATION } from "../utils/config";

const _axios = axios.create();
const { dispatch } = store;

_axios.interceptors.request.use(
  (config) => {
    config.headers.subscriptionKey = SUBSCRIPTION_KEY_VERIFICATION;

    dispatch(setLoadingScreen(true));
    return config;
  },
  (error) => {
    dispatch(setLoadingScreen(false));
    return error;
  }
);

_axios.interceptors.response.use(
  (response) => {
    dispatch(setLoadingScreen(false));
    return response;
  },
  (error) => {
    dispatch(setLoadingScreen(false));
    return error;
  }
);

const setLoadingScreen = (loading) => ({
  type: "GLOBAL_REDUCER::SET_LOADING_SCREEN",
  payload: loading,
});

export default _axios;
