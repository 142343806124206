import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
  Grid,
  Button,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getParameterByName } from "../../utils/utils";
import { withRouter } from "react-router-dom";

import { getTableData as getTableData } from "./actions";
import { Filters } from "../../components";
import { getCategoryBasedOnID } from "../../utils/utils";

import "./mainPage.scss";
import { SUBSCRIPTION_KEY_VERIFICATION } from "../../utils/config";

const MainPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tableData, error } = useSelector((state) => state.mainPage);
  const [page, setPage] = useState(1);
  const [goToPage, setGoToPage] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedTab, setSelectedTab] = useState("all");
  const [sortValue, setSortValue] = useState("latest");
  const [latestFirst, setLatestFirst] = useState(true);
  const [filtersObject, setFiltersObject] = useState({
    market: "",
    category: "",
    submissionNo: "",
    sharingBookingId: "",
    customerId: "",
    vehicleId: "",
    timeAndDate: "",
    timeAndDateEnd: "",
    latestFirst: true,
    selectedTab: "all",
    workOrderId: "",
    otdOrderId: "",
    caseId: "",
  });

  /*eslint-disable */
  useEffect(() => {
    const timeoutId = setTimeout(() => console.log(`I can see you're not typing. I can use "${goToPage}" now!`), 1000);

    return () => clearTimeout(timeoutId);
  }, [goToPage]);
  /*eslint-enable */

  /*eslint-disable */
  useEffect(() => {
    const customerId = getParameterByName("customerId");
    if (customerId) {
      setFiltersObject({
        ...filtersObject,
        customerId,
      });
      dispatch(getTableData(itemsPerPage, page, "", customerId, "", "", "", "", "", true, "all", "", "", "", ""));
    } else {
      dispatch(getTableData(itemsPerPage, page, "", "", "", "", "", "", "", true, "all", "", "", "", ""));
    }
  }, []);
  /*eslint-enable */

  useEffect(() => {
    setPage(tableData.paginationInfo.currentPage);
    setItemsPerPage(tableData.paginationInfo.rowsPerPage);
    // console.log(error);
  }, [tableData, error]);

  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(event.target.value);
    dispatch(
      getTableData(
        event.target.value,
        goToPage !== "" ? goToPage : page,
        filtersObject.submissionNo,
        filtersObject.customerId,
        filtersObject.vehicleId,
        filtersObject.market,
        filtersObject.category,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        filtersObject.selectedTab,
        filtersObject.workOrderId,
        filtersObject.otdOrderId,
        filtersObject.caseId,
        filtersObject.sharingBookingId
      )
    );
  };

  const handleChangePage = (event, value) => {
    setGoToPage("");
    dispatch(
      getTableData(
        itemsPerPage,
        value,
        filtersObject.submissionNo,
        filtersObject.customerId,
        filtersObject.vehicleId,
        filtersObject.market,
        filtersObject.category,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        filtersObject.selectedTab,
        filtersObject.workOrderId,
        filtersObject.otdOrderId,
        filtersObject.caseId,
        filtersObject.sharingBookingId
      )
    );
  };

  const handleChangeGoToPage = (event) => {
    setGoToPage(event.target.value);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setFiltersObject({
      ...filtersObject,
      selectedTab: tab,
    });
    dispatch(
      getTableData(
        itemsPerPage,
        page,
        filtersObject.submissionNo,
        filtersObject.customerId,
        filtersObject.vehicleId,
        filtersObject.market,
        filtersObject.category,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        tab,
        filtersObject.workOrderId,
        filtersObject.otdOrderId,
        filtersObject.caseId,
        filtersObject.sharingBookingId
      )
    );
  };

  const handleSortChange = (event) => {
    if (event.target.value === "latest") {
      setLatestFirst(true);
      dispatch(
        getTableData(
          itemsPerPage,
          page,
          filtersObject.submissionNo,
          filtersObject.customerId,
          filtersObject.vehicleId,
          filtersObject.market,
          filtersObject.category,
          filtersObject.timeAndDate,
          filtersObject.timeAndDateEnd,
          true,
          filtersObject.selectedTab,
          filtersObject.workOrderId,
          filtersObject.otdOrderId,
          filtersObject.caseId,
          filtersObject.sharingBookingId
        )
      );
    } else {
      setLatestFirst(false);
      dispatch(
        getTableData(
          itemsPerPage,
          page,
          filtersObject.submissionNo,
          filtersObject.customerId,
          filtersObject.vehicleId,
          filtersObject.market,
          filtersObject.category,
          filtersObject.timeAndDate,
          filtersObject.timeAndDateEnd,
          false,
          filtersObject.selectedTab,
          filtersObject.workOrderId,
          filtersObject.otdOrderId,
          filtersObject.caseId,
          filtersObject.sharingBookingId
        )
      );
    }
    setSortValue(event.target.value);
  };

  const goToPageClick = () => {
    dispatch(
      getTableData(
        itemsPerPage,
        goToPage,
        filtersObject.submissionNo,
        filtersObject.customerId,
        filtersObject.vehicleId,
        filtersObject.market,
        filtersObject.category,
        filtersObject.timeAndDate,
        filtersObject.timeAndDateEnd,
        filtersObject.latestFirst,
        filtersObject.selectedTab,
        filtersObject.workOrderId,
        filtersObject.otdOrderId,
        filtersObject.caseId,
        filtersObject.sharingBookingId
      )
    );
  };

  const submitFiltersParent = (
    market,
    category,
    submissionNo,
    sharingBookingId,
    customerId,
    vehicleId,
    timeAndDate,
    timeAndDateEnd,
    workOrderId,
    otdOrderId,
    caseId
  ) => {
    setFiltersObject({
      market,
      category,
      submissionNo,
      sharingBookingId,
      customerId,
      vehicleId,
      timeAndDate,
      timeAndDateEnd,
      latestFirst,
      selectedTab,
      workOrderId,
      otdOrderId,
      caseId,
    });
    dispatch(
      getTableData(
        itemsPerPage,
        page,
        submissionNo,
        customerId,
        vehicleId,
        market,
        category,
        timeAndDate,
        timeAndDateEnd,
        latestFirst,
        selectedTab,
        workOrderId,
        otdOrderId,
        caseId,
        sharingBookingId
      )
    );
  };

  const goToDetails = (submissionId) => {
    props.history.push(`details?submissionId=${submissionId}`);
  };

  return (
    <Paper className="main-page">
      <div>
        <Filters
          submitFiltersParent={(
            market,
            category,
            submissionNo,
            sharingBookingId,
            customerId,
            vehicleId,
            timeAndDate,
            timeAndDateEnd,
            workOrderId,
            otdOrderId,
            caseId
          ) =>
            submitFiltersParent(
              market,
              category,
              submissionNo,
              sharingBookingId,
              customerId,
              vehicleId,
              timeAndDate,
              timeAndDateEnd,
              workOrderId,
              otdOrderId,
              caseId
            )
          }
          customerIdFromUrl={filtersObject.customerId}
        />
      </div>
      <div className="tab-container">
        <ul>
          <li className={selectedTab === "all" ? "selected" : ""} onClick={() => handleTabChange("all")}>
            {t("mainPageAll")} ({tableData.paginationInfo.allSubmissionCount})
          </li>
          <li className={selectedTab === "issue" ? "selected" : ""} onClick={() => handleTabChange("issue")}>
            {t("mainPageIssueReportTab")} ({tableData.paginationInfo.issueReportCount})
          </li>
          <li className={selectedTab === "condition" ? "selected" : ""} onClick={() => handleTabChange("condition")}>
            {t("mainPageConditionReportTab")} ({tableData.paginationInfo.conditionReportCount})
          </li>
          <li className={selectedTab === "sharing" ? "selected" : ""} onClick={() => handleTabChange("sharing")}>
            {t("mainPageSharingReportTab")} ({tableData.paginationInfo.sharingReportCount})
          </li>
          <li className="no-underline-select">
            <div className="">
              <Select id="market-select" native value={sortValue} onChange={handleSortChange}>
                <option value="latest">{t("mainPageSortLatest")}</option>
                <option value="oldest">{t("mainPageSortOldest")}</option>
              </Select>
            </div>
          </li>
        </ul>
      </div>
      <Button
        className="add-submission-button"
        variant="contained"
        onClick={() => props.history.push(`add-submission?subscriptionKey=${SUBSCRIPTION_KEY_VERIFICATION}`)}
      >
        {t("mainPageAddSubmission")}
      </Button>
      {error !== "" && (
        <Grid container spacing={3} className="error-wrapper">
          <Typography align="center" color="error">
            {error}
          </Typography>
        </Grid>
      )}
      {error === "" && (
        <>
          <TableContainer className="table-container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableData &&
                    tableData.tableHeaders &&
                    tableData.tableHeaders.map((header) => (
                      <TableCell key={header.id} className="table-head">
                        {header.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData.tableRows &&
                  tableData.tableRows.map((row, i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.vehicleId + Math.random()}>
                      <TableCell>
                        {page > 1 && tableData.paginationInfo.totalPages > 1
                          ? (page - 1) * itemsPerPage + 1 + i
                          : i + 1}
                      </TableCell>
                      {row.submissionId ? (
                        <TableCell onClick={() => goToDetails(row.submissionId)}>
                          <span className="submission-id">{row.submissionId}</span>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                      {row.userId ? <TableCell>{row.userId}</TableCell> : <TableCell></TableCell>}
                      {row.vehicleId ? <TableCell>{row.vehicleId}</TableCell> : <TableCell></TableCell>}
                      {row.market ? <TableCell>{row.market}</TableCell> : <TableCell></TableCell>}
                      {row.bookingId ? <TableCell>{row.bookingId}</TableCell> : <TableCell></TableCell>}
                      {row.caseId ? <TableCell>{row.caseId}</TableCell> : <TableCell></TableCell>}
                      {row.workOrderId ? <TableCell>{row.workOrderId}</TableCell> : <TableCell></TableCell>}
                      {row.otdOrderId ? <TableCell>{row.otdOrderId}</TableCell> : <TableCell></TableCell>}
                      {row.createdAtUtc ? (
                        <TableCell>{`${moment(row.createdAtUtc).utc().format("YYYY-MM-DD HH:mm")} UTC`}</TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                      {row.reports ? <TableCell>{row.reports.length}</TableCell> : <TableCell></TableCell>}
                      {row.category ? (
                        <TableCell>{getCategoryBasedOnID(row.category)}</TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pagination-container">
            <div className="items-per-page-container">
              <FormControl className="items-per-page-form-control">
                <InputLabel shrink htmlFor="items-per-page">
                  {t("mainPageItemsPerPage")}
                </InputLabel>
                <NativeSelect value={itemsPerPage} onChange={handleChangeItemsPerPage}>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </NativeSelect>
              </FormControl>
            </div>
            <Pagination
              className="pagination"
              count={tableData && tableData.paginationInfo ? tableData.paginationInfo.totalPages : 0}
              page={parseInt(page)}
              onChange={handleChangePage}
            />
            <Typography>
              <input
                className="goToPage-input"
                type="number"
                value={goToPage}
                onChange={handleChangeGoToPage}
                min="1"
                max={tableData && tableData.paginationInfo ? tableData.paginationInfo.totalPages : 0}
              />
              <span className="goToPage-go-button" onClick={goToPageClick}>
                {t("mainPageGo")}
              </span>
            </Typography>
          </div>
        </>
      )}
    </Paper>
  );
};

export default withRouter(MainPage);
