export const getCategoryBasedOnID = (id) => {
  switch (id) {
    case 1:
      return "Car sharing";
    case 2:
      return "Damage";
    case 3:
      return "Problem";
    case 4:
      return "Other";
    case 5:
      return "Return";
    case 6:
      return "Delivery";
  }
};

export const getParameterByName = (name, url = window.location.href) => {
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, "\\$&");
  /*eslint-enable */
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
