import * as types from "./actionNames";
import { createSubmissionApiCall } from "../services";

export const postCreateSubmission = (data) => async (dispatch) => {
  try {
    const response = await createSubmissionApiCall(data);

    if ((response && response.status === 200) || response.status === 201) {
      dispatch(postCreateSubmissionSuccess(response.data));
    } else {
      dispatch(postCreateSubmissionError(response.response.data.message));
    }
  } catch (error) {
    dispatch(postCreateSubmissionError(true));
  }
};

const postCreateSubmissionSuccess = (response) => ({
  type: types.CREATE_SUBMISSION_SUCCESS,
  payload: response,
});

const postCreateSubmissionError = (error) => ({
  type: types.CREATE_SUBMISSION_ERROR,
  error,
});
