import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel, Grid, Button, Paper, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { postCreateSubmission } from "./actions";

import "./addSubmission.scss";
import { SUBSCRIPTION_KEY_VERIFICATION } from "../../utils/config";

const AddSubmissionPage = (props) => {
  const { t } = useTranslation();
  const { createSubmissionSuccess } = useSelector((state) => state.addSubmission);
  const dispatch = useDispatch();
  const [market, setMarket] = useState("");
  const [category, setCategory] = useState("1");
  const [customerId, setCustomerId] = useState("");
  const [sharingBookingId, setSharingBookingId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [otdOrderId, setOtdOrderId] = useState("");
  const [workOrderId, setWorkOrderId] = useState("");
  const [errors, setErrors] = useState({
    customerId: false,
    sharingBookingId: false,
    vehicleId: false,
    caseId: false,
    otdOrderId: false,
    workOrderId: false,
  });

  useEffect(() => {
    if (createSubmissionSuccess.submissionId !== "") {
      const win = window.open(`/details?submissionId=${createSubmissionSuccess.submissionId}`, "_self");
      // const win = window.open(`/`, "_self");
      win.focus();
    }
  }, [createSubmissionSuccess.submissionId]);

  const goBackToSearch = () => {
    props.history.push(`/`);
  };

  const handleChangeMarket = (event) => {
    setMarket(event.target.value);
  };

  const handleChangeReportType = (event) => {
    setCategory(event.target.value);
  };

  const validateFields = (fieldName) => {
    const renderErrMsg = (field) => `${field} ${t("genericErrorMandatoryText")}`;

    switch (fieldName) {
      case "customerId":
        errors[fieldName] = !customerId
          ? renderErrMsg(t("genericCustomerId"))
          : customerId % 1 !== 0
          ? t("genericCustomerIdError")
          : false;
        break;

      case "vehicleId":
        errors[fieldName] = !vehicleId ? renderErrMsg(t("genericVehicleId")) : false;
        break;

      case "market":
        errors[fieldName] = !market ? t("genericMarketError") : false;
        break;
      case "validateAll":
        errors["customerId"] = !customerId
          ? renderErrMsg(t("genericCustomerId"))
          : customerId % 1 !== 0
          ? t("genericCustomerIdError")
          : false;
        errors["vehicleId"] = !vehicleId ? renderErrMsg(t("genericVehicleId")) : false;
        errors["market"] = !market ? t("genericMarketError") : false;
        break;

      default:
        break;
    }

    setErrors({
      ...errors,
      errors,
    });
  };

  const renderFieldError = (fieldName) => {
    return errors[fieldName] ? <span className="error-msg">{errors[fieldName]}</span> : <span />;
  };

  const onCreateClick = async () => {
    await validateFields("validateAll");
    const errorsToCheck = errors;

    const checkFields = (fields) => {
      let valid = true;
      for (const property in fields) {
        if (fields[property] !== false) {
          valid = false;
        }
      }
      return valid;
    };

    delete errorsToCheck.errors;
    const isFormValid = checkFields(errorsToCheck);

    const data = {
      customerId: parseInt(customerId),
      vehicleId,
      market,
      category: parseInt(category),
      bookingId: sharingBookingId,
      caseId,
      workOrderId,
      otdOrderId,
    };

    if (isFormValid) {
      dispatch(postCreateSubmission(data));
    }
  };

  return (
    <div className="add-submission-page">
      <Paper elevation={3} className="paper-container">
        <Grid container justify="center" alignItems="center" className="grid-container">
          <Grid item sm={12}>
            <p className="back-to-search" onClick={goBackToSearch}>
              {t("detailsPageBack")}
            </p>
            <div className="title-container">
              <h2 className="add-submission-title">{t("addSubmissionTitle")}</h2>
            </div>
          </Grid>
        </Grid>

        <Grid container className="grid-container padding-vertical-5">
          <Grid item sm={6} md={3}>
            <div className={`add-submission-generic-input-wrapper customer-id ${errors.customerId && "error"}`}>
              <InputLabel className="label" htmlFor="custome-id">
                {t("detailsPageLabelCustomerId")} <span className="mandatory-star">*</span>
              </InputLabel>
              <input
                id="customer-id"
                type="number"
                value={customerId}
                onBlur={() => validateFields("customerId")}
                onChange={(event) => setCustomerId(event.target.value)}
                className="input-text"
              />
            </div>
          </Grid>
          <Grid item sm={6} md={3}>
            <div
              className={`add-submission-generic-input-wrapper sharing-booking-id ${
                errors.sharingBookingId && "error"
              }`}
            >
              <InputLabel className="label" htmlFor="sharing-booking-id">
                {t("addSubmissionSharingBooking")}
                {/* <br /> {t("addSubmissionId")} <span className="mandatory-star">*</span> */}
              </InputLabel>
              <input
                id="sharing-booking-id"
                type="text"
                value={sharingBookingId}
                onChange={(event) => setSharingBookingId(event.target.value)}
                className="input-text"
              />
            </div>
          </Grid>
          <Button className="create-button" variant="contained" onClick={onCreateClick}>
            {t("addSubmissionCreate")}
          </Button>
        </Grid>

        <Grid container className="grid-container padding-vertical-5">
          <Grid item sm={6} md={3}>
            <div className={`add-submission-generic-input-wrapper vehicle-id ${errors.vehicleId && "error"}`}>
              <InputLabel className="label" htmlFor="vehicle-id">
                {t("detailsPageLabelVehicleId")} <span className="mandatory-star">*</span>
              </InputLabel>
              <input
                id="vehicle-id"
                type="text"
                value={vehicleId}
                onBlur={() => validateFields("vehicleId")}
                onChange={(event) => setVehicleId(event.target.value)}
                className="input-text"
              />
            </div>
          </Grid>
          <Grid item sm={6} md={3}>
            <div className={`add-submission-generic-input-wrapper case-id ${errors.caseId && "error"}`}>
              <InputLabel className="label" htmlFor="case-id">
                {t("detailsPageLabelCaseId")} <span className="mandatory-star"></span>
              </InputLabel>
              <input
                id="case-id"
                type="text"
                value={caseId}
                onChange={(event) => setCaseId(event.target.value)}
                className="input-text"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container className="grid-container padding-vertical-5">
          <Grid item sm={6} md={3}>
            <div className={`add-submission-generic-input-wrapper market ${errors.market && "error"}`}>
              <InputLabel className="label" htmlFor="market-select">
                {t("detailsPageLabelMarket")}
              </InputLabel>
              <Select
                id="market-select"
                native
                value={market}
                onChange={handleChangeMarket}
                onBlur={() => validateFields("market")}
              >
                <option value="">{t("generalLabelAll")}</option>
                <option value="SE">SE</option>
                <option value="NL">NL</option>
                <option value="BE">BE</option>
                <option value="DE">DE</option>
                <option value="IT">IT</option>
                <option value="ES">ES</option>
                <option value="FR">FR</option>
              </Select>
            </div>
          </Grid>
          <Grid item sm={6} md={3}>
            <div className={`add-submission-generic-input-wrapper otd-order-id ${errors.otdOrderId && "error"}`}>
              <InputLabel className="label" htmlFor="otd-order-id">
                {t("detailsPageLabelOtdOrderId")} <span className="mandatory-star"></span>
              </InputLabel>
              <input
                id="otd-order-id"
                type="text"
                value={otdOrderId}
                onChange={(event) => setOtdOrderId(event.target.value)}
                className="input-text"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container className="grid-container padding-vertical-5">
          <Grid item sm={6} md={3}>
            <div className="add-submission-generic-input-wrapper case-id">
              <InputLabel className="label" htmlFor="report-type-select">
                {t("addSubmissionSelectCategory")}
              </InputLabel>
              <Select id="report-type-select" native value={category} onChange={handleChangeReportType}>
                <option value="1">{t("addSubmissionCarSharingReport")}</option>
                <option value="2">{t("addSubmissionDamageReport")}</option>
                <option value="3">{t("addSubmissionProblemReport")}</option>
                <option value="4">{t("addSubmissionOtherReport")}</option>
                <option value="5">{t("addSubmissionReturnReport")}</option>
                <option value="6">{t("addSubmissionDeliveryReport")}</option>
              </Select>
            </div>
          </Grid>
          <Grid item sm={6} md={3}>
            <div className={`add-submission-generic-input-wrapper work-order-id ${errors.workOrderId && "error"}`}>
              <InputLabel className="label" htmlFor="work-order-id">
                {t("detailsPageLabelWorkorderId")} <span className="mandatory-star"></span>
              </InputLabel>
              <input
                id="work-order-id"
                type="text"
                value={workOrderId}
                onChange={(event) => setWorkOrderId(event.target.value)}
                className="input-text"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" className="grid-container error-container">
          {renderFieldError("customerId")}
          {renderFieldError("vehicleId")}
          {renderFieldError("market")}
        </Grid>

        <Grid container justify="center" alignItems="center" className="grid-container footer-grid">
          <div className="footer">
            <p>{t("addSubmissionFooterText")}</p>
          </div>
        </Grid>
      </Paper>
    </div>
  );
};

export default withRouter(AddSubmissionPage);
