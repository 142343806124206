import React from "react";

import { Logo } from "../../assets/images";

import "./notFoundPage.scss";

const NotFoundPage = (props) => {
  return (
    <div className="container">
      <div className="header">
        <Logo />
      </div>
      <h1>
        Not found <span>:(</span>
      </h1>
      <p>We are sorry, but the page you are looking for is not here.</p>

      <i>404</i>
    </div>
  );
};

export default NotFoundPage;
