import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { getCategoryBasedOnID } from "../../utils/utils";

import LynkcoFontRegular from "../../assets/fonts/LynkcoType-Regular.ttf";

const DetailsPDFPage = ({ pdfDetails }) => {
  const { t } = useTranslation();

  Font.register({
    family: "LynkcoType-Regular",
    format: "truetype",
    src: LynkcoFontRegular,
  });
  // Create styles
  const styles = StyleSheet.create({
    page: {
      padding: 50,
      fontFamily: "LynkcoType-Regular",
    },
    title: {
      fontSize: 20,
      marginBottom: 30,
      color: "#3b3b3b",
    },
    sectionWrapper: {
      flexDirection: "row",
    },
    section: {
      marginBottom: 15,
    },
    sectionTile: {
      fontSize: 8,
      color: "#c1c1c1",
      marginBottom: 5,
    },
    sectionValue: {
      fontSize: 12,
      color: "#4f4f4f",
      flexWrap: "nowrap",
    },
    firstSectionOfGroup: {
      width: 250,
    },
    reportsWrapper: {
      marginTop: 10,
    },
    reportsTitle: {
      fontSize: 16,
      color: "#3b3b3b",
      marginBottom: 10,
      marginTop: 20,
    },
    reportsSection: {
      flexDirection: "row",
      marginBottom: 30,
    },
    largeWidth: {
      width: 150,
      marginRight: 15,
    },
    mediumWidth: {
      width: 75,
      marginRight: 15,
    },
    smallWidth: {
      width: 50,
      marginRight: 15,
    },
    generalWidth: {
      width: 250,
      marginRight: 15,
    },
    attachmentImgWrapper: {
      flexDirection: "row",
      marginBottom: 20,
    },
    noMarginBottom: {
      marginBottom: 0,
    },
    image: {
      width: 100,
      marginRight: 20,
    },
    grayReportBG: {
      backgroundColor: "#F2F2F2",
    },
    descriptionValue: {
      width: 400,
    },
    genericReportWrapper: {
      width: 500,
      padding: 20,
      border: "#dfdfdf",
      borderTopWidth: 1,
      borderBottomWidth: 1,
    },
    pageNumberCount: {
      position: "absolute",
      color: "#c1c1c1",
      right: 10,
      top: 10,
      fontSize: 8,
    },
    attachmentVideo: {
      width: 100,
      height: 50,
      marginRight: 20,
      backgroundColor: "#669CDB",
      justifyContent: "center",
      textAlign: "center",
      color: "#3b3b3b",
    },
    videoText: {
      fontSize: 12,
    },
    attachmentWraper: {
      display: "flex",
      flexDirection: "column",
    },
    fontS10: {
      fontSize: 10,
      color: "#4f4f4f",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Text
          style={styles.pageNumberCount}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber}(${totalPages})`}
          fixed
        />
        <View style={styles.title}>
          <Text>{t("pdfSubmissionDetails")}</Text>
        </View>
        <View style={styles.sectionWrapper}>
          <View style={[styles.section, styles.firstSectionOfGroup]}>
            <Text style={styles.sectionTile}>{t("filtersSubmissionNo")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.submissionId}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelCategory")}</Text>
            <Text style={styles.sectionValue}>{getCategoryBasedOnID(pdfDetails.category)}</Text>
          </View>
        </View>
        <View style={styles.sectionWrapper}>
          <View style={[styles.section, styles.firstSectionOfGroup]}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelCustomerId")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.userId}</Text>
          </View>
          <View style={[styles.section, styles.firstSectionOfGroup]}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelMarket")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.market}</Text>
          </View>
        </View>
        <View style={styles.sectionWrapper}>
          <View style={[styles.section, styles.firstSectionOfGroup]}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelVehicleId")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.vehicleId}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelSharingBookingID")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.bookingId}</Text>
          </View>
        </View>
        <View style={styles.sectionWrapper}>
          <View style={[styles.section, styles.firstSectionOfGroup]}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelSubmitted")}</Text>
            <Text style={styles.sectionValue}>{`${moment(pdfDetails.createdAtUtc)
              .utc()
              .format("YYYY-MM-DD HH:mm")} UTC`}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelWorkorderId")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.workOrderId}</Text>
          </View>
        </View>
        <View style={styles.sectionWrapper}>
          <View style={[styles.section, styles.firstSectionOfGroup]}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelCaseId")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.caseId}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTile}>{t("detailsPageLabelOtdOrderId")}</Text>
            <Text style={styles.sectionValue}>{pdfDetails.otdOrderId}</Text>
          </View>
        </View>
        <Text style={styles.reportsTitle}>
          {t("pdfReports")} ({pdfDetails.reports ? pdfDetails.reports.length : "0"})
        </Text>
        {pdfDetails.reports &&
          pdfDetails.reports.length !== 0 &&
          pdfDetails.reports.map((report, index) => (
            <View key={report.reportId} style={[styles.genericReportWrapper, !(index % 2) && styles.grayReportBG]}>
              <View style={styles.reportsSection}>
                <View style={styles.smallWidth}>
                  <Text style={styles.sectionTile}>{t("pdfNo")}</Text>
                  <Text style={styles.sectionValue}>{index + 1}</Text>
                </View>
                <View style={styles.generalWidth}>
                  <Text style={styles.sectionTile}>{t("detailsPageLabelReportId")}</Text>
                  <Text style={styles.sectionValue}>{report.reportId}</Text>
                </View>
                <View style={styles.generalWidth}>
                  <Text style={styles.sectionTile}>{t("detailsPageLabelCreated")}</Text>
                  {report.occurredAtUtc && pdfDetails.category !== 1 && (
                    <Text style={styles.sectionValue}>{`${moment(report.occurredAtUtc)
                      .utc()
                      .format("YYYY-MM-DD HH:mm")} UTC`}</Text>
                  )}
                </View>
              </View>
              <View style={styles.reportsSection}>
                <View style={styles.largeWidth}>
                  <Text style={styles.sectionTile}>{t("detailsPageLabelLocation")}</Text>
                  <Text style={styles.sectionValue}>{report.location}</Text>
                </View>
                <View style={styles.mediumWidth}>
                  <Text style={styles.sectionTile}>{t("detailsPageLabelCategory")}</Text>
                  <Text style={styles.sectionValue}>{getCategoryBasedOnID(pdfDetails.category)}</Text>
                </View>
                <View style={styles.mediumWidth}>
                  <Text style={styles.sectionTile}>{t("detailsPageLabelCause")}</Text>
                  <Text style={styles.sectionValue}>{report.cause}</Text>
                </View>
                <View style={styles.smallWidth}>
                  <Text style={styles.sectionTile}>{t("detailsPageLabelRecurring")}</Text>
                  {pdfDetails.category !== 1 && (
                    <Text style={styles.sectionValue}>{report.recurrence ? "YES" : "NO"}</Text>
                  )}
                </View>
              </View>
              <View style={styles.reportsSection}>
                <View>
                  <Text style={styles.sectionTile}>{t("detailsPageLabelDescription")}</Text>
                  <Text style={[styles.sectionValue, styles.descriptionValue]}>{report.description}</Text>
                </View>
              </View>
              <Text style={styles.sectionTile}>
                {t("detailsPageLabelAttachments")} ({report.attachments && report.attachments.length})
              </Text>
              <View style={styles.attachmentImgWrapper}>
                {report.attachments &&
                  report.attachments.map((attachment, index) =>
                    index <= 3 && attachment.contentType !== null && attachment.contentType.includes("video") ? (
                      <View key={attachment.attachmentId}>
                        <View style={styles.attachmentVideo}>
                          <Text style={styles.videoText}>{t("detailsPageLabelVideo")}</Text>
                        </View>
                        <Text style={styles.fontS10}>{attachment.positionName}</Text>
                      </View>
                    ) : (
                      index <= 3 && (
                        <View style={styles.attachmentWraper} key={attachment.attachmentId}>
                          <Image style={styles.image} src={attachment.absoluteUri} />
                          <Text style={styles.fontS10}>{attachment.positionName}</Text>
                        </View>
                      )
                    )
                  )}
              </View>
              <View
                style={[
                  styles.attachmentImgWrapper,
                  report.attachments && report.attachments.length <= 8 && styles.noMarginBottom,
                ]}
              >
                {report.attachments &&
                  report.attachments.map((attachment, index) =>
                    index >= 4 &&
                    index < 8 &&
                    attachment.contentType !== null &&
                    attachment.contentType.includes("video") ? (
                      <View key={attachment.attachmentId}>
                        <View style={styles.attachmentVideo}>
                          <Text style={styles.videoText}>{t("detailsPageLabelVideo")}</Text>
                        </View>
                        <Text style={styles.fontS10}>{attachment.positionName}</Text>
                      </View>
                    ) : (
                      index >= 4 &&
                      index < 8 && (
                        <View style={styles.attachmentWraper} key={attachment.attachmentId}>
                          <Image style={styles.image} src={attachment.absoluteUri} />
                          <Text style={styles.fontS10}>{attachment.positionName}</Text>
                        </View>
                      )
                    )
                  )}
              </View>
              <View
                style={[
                  styles.attachmentImgWrapper,
                  report.attachments && report.attachments.length <= 12 && styles.noMarginBottom,
                ]}
              >
                {report.attachments &&
                  report.attachments.map((attachment, index) =>
                    index >= 8 &&
                    index < 12 &&
                    attachment.contentType !== null &&
                    attachment.contentType.includes("video") ? (
                      <View key={attachment.attachmentId}>
                        <View style={styles.attachmentVideo}>
                          <Text style={styles.videoText}>{t("detailsPageLabelVideo")}</Text>
                        </View>
                        <Text style={styles.fontS10}>{attachment.positionName}</Text>
                      </View>
                    ) : (
                      index >= 8 &&
                      index < 12 && (
                        <View style={styles.attachmentWraper} key={attachment.attachmentId}>
                          <Image style={styles.image} src={attachment.absoluteUri} />
                          <Text style={styles.fontS10}>{attachment.positionName}</Text>
                        </View>
                      )
                    )
                  )}
              </View>
            </View>
          ))}
      </Page>
    </Document>
  );
};

export default DetailsPDFPage;
