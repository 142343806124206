import {
  FETCH_DETAILS_BASED_ON_SUBMISSION_ID,
  FETCH_DETAILS_BASED_ON_SUBMISSION_ID_ERROR,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_ERROR,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_ERROR,
  UPLOAD_ERROR,
  UPLOAD_SUCCESS,
  UPDATE_SUBMISSION_SUCCESS,
  UPDATE_SUBMISSION_ERROR,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_ERROR,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_PENDING,
} from "../actions/actionNames";

const initialState = {
  details: {
    createdAtUtc: "",
    userId: "",
    vehicleId: "",
    bookingId: "",
    market: "",
  },
  deleteSuccess: "not-set",
  error: "",
  uploadSuccess: false,
  uploadError: false,
  deleteAttachment: false,
  updateSubmissionStatus: false,
  createReportStatus: "not-set",
  fileBlob: "",
  fileDownloadPending: false,
};

const fetchDetailsBasedOnSubmissionId = (state, details) => ({
  ...state,
  details,
  deleteSuccess: "not-set",
  error: "",
  uploadSuccess: false,
  updateSubmissionStatus: false,
  createReportStatus: "not-set",
  fileBlob: "",
});
const fetchDetailsBasedOnSubmissionIdError = (state, error) => ({
  ...state,
  error,
  deleteSuccess: "not-set",
  uploadSuccess: false,
  updateSubmissionStatus: false,
  createReportStatus: "not-set",
  fileBlob: "",
});
const deleteReportSuccess = (state, deleteSuccess) => ({ ...state, deleteSuccess, fileBlob: "" });
const deleteReportError = (state, error) => ({ ...state, error, deleteSuccess: "not-set", fileBlob: "" });
const deleteAttachmentSuccess = (state, deleteAttachment) => ({
  ...state,
  deleteAttachment,
  fileBlob: "",
});
const deleteAttachmentError = (state, error) => ({
  ...state,
  error,
  fileBlob: "",
});
const uploadSuccess = (state, success) => ({
  ...state,
  error: "",
  deleteSuccess: "not-set",
  uploadSuccess: success,
  uploadError: false,
  fileBlob: "",
});
const uploadError = (state, error) => ({
  ...state,
  error,
  deleteSuccess: "not-set",
  uploadError: error,
  fileBlob: "",
});

const updateSubmissionSuccess = (state, updateSubmissionStatus) => ({
  ...state,
  updateSubmissionStatus,
  updateSubmissionError: false,
  fileBlob: "",
});
const updateSubmissionError = (state, error) => ({
  ...state,
  updateSubmissionStatus: false,
  updateSubmissionError: error,
  fileBlob: "",
});

const createReportSuccess = (state, createReportStatus) => ({ ...state, createReportStatus, fileBlob: "" });
const createReportError = (state, error) => ({ ...state, error, createReportStatus: "not-set", fileBlob: "" });

const downloadFile = (state, file) => ({
  ...state,
  fileBlob: file,
  fileDownloadPending: false,
});

const downloadFilePending = (state, status) => ({
  ...state,
  fileDownloadPending: status,
});

const detailsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DETAILS_BASED_ON_SUBMISSION_ID:
      return fetchDetailsBasedOnSubmissionId(state, action.payload);
    case FETCH_DETAILS_BASED_ON_SUBMISSION_ID_ERROR:
      return fetchDetailsBasedOnSubmissionIdError(state, true);
    case DELETE_REPORT_SUCCESS:
      return deleteReportSuccess(state, action.payload);
    case DELETE_REPORT_ERROR:
      return deleteReportError(state, action.error);
    case DELETE_ATTACHMENT_SUCCESS:
      return deleteAttachmentSuccess(state, action.payload);
    case DELETE_ATTACHMENT_ERROR:
      return deleteAttachmentError(state, action.error);
    case UPLOAD_SUCCESS:
      return uploadSuccess(state, action.payload);
    case UPLOAD_ERROR:
      return uploadError(state, action.error);
    case UPDATE_SUBMISSION_SUCCESS:
      return updateSubmissionSuccess(state, action.payload);
    case UPDATE_SUBMISSION_ERROR:
      return updateSubmissionError(state, action.error);
    case CREATE_REPORT_SUCCESS:
      return createReportSuccess(state, action.payload);
    case CREATE_REPORT_ERROR:
      return createReportError(state, action.error);
    case DOWNLOAD_FILE_SUCCESS:
      return downloadFile(state, action.payload);
    case DOWNLOAD_FILE_PENDING:
      return downloadFilePending(state, action.status);
    default:
      return state;
  }
};

export default detailsPageReducer;
